export const translatioanEn = {
  title1: 'Unlock the Best Mortgage Rates for Your Dream Home.',
  infoTitle: 'The max loan amount, monthly installment and rate depends on your:',
  infoItem1: 'Work sector',
  infoItem2: 'Salary',
  infoItem3: 'Government support eligibility',
  infoText: "Let's find out your financing opportunities. We will guide you through the whole process.",
  btn1: 'Start now',
  title2: 'Please tell us about your work',
  selectButton1: 'Job selection',
  selectBtn1: "Government",
  selectBtn2: "Military",
  selectBtn3: "Private (SME)",
  selectBtn4: "Private (Enterprise)",
  selectBtn5: "Retired",
  Rank: "Rank",
  Pilote: 'Are you a pilot?',
  inputTitle1: 'Employer name',
  placeHolder1: 'Type your name',
  inputTitle2: 'Basic salary',
  placeHolder2: 'Type your salary here',
  inputTitle3: 'Your net monthly salary after deducting insurance',
  placeHolderInfo: "Monthly salary deposited in the bank account after deducting insurance",
  placeHolder3: 'Type your salary here',
  inputTitle4: 'Service start date',
  placeHolder4: 'Type your name',
  inputTitle5: 'Salary bank',
  placeHolder5: 'Select bank',
  selectButton2: "How long you have been at this job?",
  selectBtn6: "1-3 months",
  selectBtn7: "3-6 months",
  selectBtn8: "6+ months",
  yes: 'Yes',
  no: 'No',
  nextBtn: 'Next',
  title3: 'Tell us about your liabilities',
  liabilitiesTitle: 'How much is your total monthly financial obligations (total monthly loan installment) in Saudi riyals and number of years remaining for obligations',
  liabilitiesSliderTitle: 'Number of years remaining for obligations',
  liabilities1: 'Personal',
  liabilities2: 'Car',
  liabilities3: 'Mortgage',
  liabilities4: 'Credit card',
  liabilitiesTable: "Added liabilities",
  add: 'Add',
  valueTitle: 'Added liabilities',
  monthly: "Monthly",
  valueText: "months to complete",
  popupname: "Add liability",
  installment: 'Monthly installment',
  complete: "Months to complete",
  title4: 'SIMAH Record',
  simahText: 'Do you currently have defaults on payments with SIMAH?',
  simahValue1: 'Yes',
  simahValuw2: 'No',
  title5: "Your first Home without tax",
  infoHeader1: 'Royal Order No. (A \ 86) dated 4/18/1439 AH stipulates that',
  infoText1: "the state shall bear value-added tax on an amount not exceeding (1 million riyals) of the purchase price of a citizen’s first home in order to reduce the cost of owning a suitable home for citizens.",
  question: "Is this your first home?",
  value6: 'Yes, this is my first home',
  value7: "No, I already have a house before",
  title6: 'Housing support program',
  infoHeader2: 'The updated housing support program It is a way of facilitating home ownership by providing non-refundable financial support in a fixed amount of SAR 100,000 or SAR 150,000, according to the approved product matrix.',
  infoText2: "The Ministry of Housing provided a support amount of a certain percentage of the amount of profits, up to a maximum of 500,000 riyals of the total financing amount.",
  Eligible: "Are you eligible for the “Sakani” support program?",
  isMortgageReady: "Did you find a suitable property?",
  notSure: "Not Sure",
  moneyType: 'SAR',
  title7: 'Congratulations!',
  text1: 'Your maximum mortgage limit is',
  text2: 'You have no recommendations form banks at this time',
  text3: 'You need to pass KYC by Nafad, check your Simah report to get final offers from banks.',
  startBtn: "Start Now",
  Previous: 'Previous',
  preLastBtn: 'Calculate your mortgage',
  Emkan: "Emkan",
  lastText: "You’re pre-approved for up to:",
  lastText2: "We will contact you very soon",
  liabilitiesType1: 'With current liabilities',
  liabilitiesType2: 'Without liabilities',
  liabilitiesType3: "Maximum mortgage amount",
  Up_to: 'Up to',
  ammount: "Amount",
  month6: '6 month',
  year5: '5 year',
  jobTypeRequired: "Please select a job type.",
  employerNameRequired: "Please enter your employer's name.",
  militaryRankRequired: "Please select a military rank.",
  piloteRequired: "Please specify if you are a pilot.",
  netSalaryRequired: "Please enter your net salary.",
  salaryComparison: "Net salary cannot be less than basic salary.",
  basicSalaryRequired: "Please enter your basic salary.",
  jobDateRequired: "Please select a job start date.",
  salaryBankRequired: "Please select your salary bank.",
  liabilitiesRequired: "Please enter your liabilities",
  typeOfMortgage: 'Type of Mortgage',
  typeOfMortgageOptions: {
    readyUnit: 'Ready unit',
    saleOnTheMap: 'sale on the map',
    selfBuild: 'self-build',
    other: 'other',
  },
  typeOfLoansOptions: {
    personalLoan: 'Personal loan',
    mortgageLoan:'Mortgage Loan',
    carLoan: 'Car loan',
    EmkanLoan:'Emkan Loan',
    creditCardLoan:'Credit Card loan',
    other: 'other',
  },
  doYouHaveAnyLoans:'Do you have any loans?',
  typeOfLoans:'Type of Loans',
  purchaseIntentionsTitle:'Complete the following details and we will guide you to the perfect property for you.',
  whereAreYouInYourHomeBuyingJourney:'Where are you in your home-buying journey?*',
  whereAreYouInYourHomeBuyingJourneyOptions:{
    imThinkingAboutBuying:'I\'m thinking about buying',
    tourOpenHouse:'Touring open houses',
    makeOfferOnProperty:'Making offers on a property',
    iHaveSignedPurchaseContract:'I\'ve signed a purchase contract'
  },
  whenAreYouLookingToBuy:"When are you looking to buy?*",
  whenAreYouLookingToBuyOptions:{
    zeroToThreeMonths:'0 - 3 months',
    fourToSixMonths:'4 - 6 months',
    sixMonthPlus:'+6 months',
    notSure:'Not sure',
  },
  howWillYouUseYourNewHome:"How will you use your new home?*",
  howWillYouUseYourNewHomeOptions:{
    primaryResidence:'Primary residence',
    secondaryResidence:'Secondary residence',
    investmentProperty:'Investment property',
  },
  pleaseAnswerAllRequiredQuestions:'please Answer All Required Questions'
}
