/* eslint-disable react/style-prop-object */
import axios from "axios";
import {Tooltip, TooltipProps} from "@mui/material";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {FcInfo} from "react-icons/fc";
import {styled} from 'styled-components'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import Buttons from "../../components/Buttons";
import Progress from "../../components/Progress";
import BackButton from "../../components/BackButton";
import ButtonWithRadio from "../../components/ButtonWithRadio";

import {gaTrigger} from "../../Utils/gaTrigger";
import {setMax, setMin} from "../../Utils/storage";
import {RADIO_BUTTONS_NAME} from "./HomePurchaseIntentions/util";

const getParsedLocalStorage = (key, defaultValue = 0) => {
  const value = localStorage.getItem(key);
  return value ? parseInt(JSON.parse(value)) : defaultValue;
};


const StyledIcon = styled(FcInfo)`
  & > circle:first-child {
    fill: var(--Brand-Green, #52B44A);
  }
`;


const TooltipIsThisYourFirstHome = ({title}) => {
  const {t} = useTranslation();


  return (
    <Tooltip
      title={
        title
      }
      arrow
      placement="top"
    >
      <button style={{background: "none", verticalAlign: "middle"}}>
        <StyledIcon/>
      </button>
    </Tooltip>
  );
};


const Info = () => {
  const [eligible, setEligible] = useState(getParsedLocalStorage("eligible"));
  const [isFirstMortgage, setIsFirstMortgage] = useState(
    getParsedLocalStorage("isFirstMortgage")
  );
  const [token, setToken] = useState("");
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("Token") || "");
  }, []);

  const handleChange = (key, value, setState) => {
    localStorage.setItem(key, JSON.stringify(value));
    setState(value);
  };
  const formatted4QuestionData=() =>{
    const buyingJourneyOptionsData = JSON.parse(localStorage.getItem("buyingJourneyOptions"));
    return Object.fromEntries(
      Object.entries(buyingJourneyOptionsData).map(([key, value]) => {
        if (key === RADIO_BUTTONS_NAME.IS_PROPERTY_READY) {
          return [key, value === "1"];
        } else if (!isNaN(value)) {
          return [key, Number(value)];
        }
        return [key, value];
      })
    );
  }

  const updateData = (body) => {
    const buyingJourneyOptionsData = formatted4QuestionData()
    const data = {...body, ...buyingJourneyOptionsData};

    axios
      .put("/leads/update-lead", data)
      .then((response) => {
        [
          "step1",
          "step2",
          "step3",
          "step4",
          "eligible",
          "isFirstMortgage",
        ].forEach(key => localStorage.removeItem(key));
        setMax(response?.data?.maxWithout);
        setMin(response?.data?.max);
        navigate("/calendartype");
      })
      .catch((error) => toast.error(error.message));
  };

  const renderButtons = (value, currentValue, setState, labelYes, labelNo) => (
    <div>
      <ButtonWithRadio
        name={t(labelYes)}
        value={1}
        pilote={currentValue}
        setPilote={(val) => handleChange(value, val, setState)}
      />
      <ButtonWithRadio
        name={t(labelNo)}
        value={2}
        pilote={currentValue}
        setPilote={(val) => handleChange(value, val, setState)}
      />
    </div>
  );

  const getButtonIcon = (condition) =>
    condition ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
      >
        <path
          d="M16.6673 5L7.50065 14.1667L3.33398 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
      >
        <path
          d="M15 5L5 15M5 5L15 15"
          stroke="#52B44A"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  const onNext = () => {
    gaTrigger("eligible_trigger");
    localStorage.setItem("isEligible",eligible)
    updateData({
      redf: eligible === 1,
      firstMortgage: isFirstMortgage === 1,
      token,
    });
  };

  return (
    <div className="conatainer" id={'info-page'}>
      <div className="progress-container">
        <Progress done={90}/>
        <p className="progress-value">90%</p>
      </div>

      <div className="selection-container-second" style={{gap: 20}}>
        <h1 className="header-text">{t("title5")}</h1>

        <div className="info" style={{width: "100%"}}>
          <p className="info-text">
            {t("question")}
            <TooltipIsThisYourFirstHome title={
              <>
                <div>
                  <span>{t("infoHeader1")}</span>{' '}
                  <span>{t("infoText1")}</span>
                </div>
              </>
            }
            />
          </p>
          <div className="selection-container-second">
            <Buttons
              style={"100%"}
              name={t("value6")}
              value={1}
              jobType={isFirstMortgage}
              setType={(val) => handleChange("isFirstMortgage", val, setIsFirstMortgage)}
            >
              {getButtonIcon(isFirstMortgage === 1)}
            </Buttons>
            <Buttons
              style={"100%"}
              name={t("value7")}
              value={2}
              jobType={isFirstMortgage}
              setType={(val) => handleChange("isFirstMortgage", val, setIsFirstMortgage)}
            >
              {getButtonIcon(isFirstMortgage === 2)}
            </Buttons>
          </div>
        </div>

        <h1 className="header-text">{t("title6")}</h1>

        <div className="info" style={{width: "100%"}}>
          <div className="radio-wraper">
            <p className="info-text">
              {t("Eligible")}
              <TooltipIsThisYourFirstHome title={
                <>
                  <div>
                    <span>{t("infoHeader2")}</span>
                  </div>
                </>
              }
              />
            </p>
            {renderButtons("eligible", eligible, setEligible, "yes", "no")}
          </div>
        </div>
      </div>



      <div className="btn-container">
        <BackButton url="/obligations"/>
        <button
          className="green-btn"
          disabled={!eligible ||  !isFirstMortgage}
          style={{opacity: !eligible ||  !isFirstMortgage ? 0.7 : 1}}
          onClick={onNext}
        >
          {t("preLastBtn")}
        </button>
      </div>
    </div>
  );
};

export default Info;
