import {styled} from "styled-components";
import {FormControl} from "@mui/material";
const HomeTitle = styled.span`
  font-size:20px;
  font-weight: 600;
`
const StyledFormControl = styled(FormControl)`
  & > div{
    margin-bottom: 40px;
  }
`
export {
  HomeTitle,
  StyledFormControl
}
