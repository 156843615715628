import React, { useEffect, useState } from "react";
import "./Steps.css";
import { json, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MilitaryArray,
  MilitaryArrayAb,
  salaryOptions,
  salaryOptionsAb,
  typesOfMortgageDropDownValues,
} from "./../../Utils/options";
import Progress from "../../components/Progress";
import Buttons from "../../components/Buttons";
import BackButton from "../../components/BackButton";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import ButtonWithRadio from "../../components/ButtonWithRadio";
import InputWithSelect from "../../components/InputWithSelect";
import DataPicker from "../../components/DatePicker";
import { toast } from "react-toastify";
import NextButton from "../../components/NextButton";
import axios from "axios";
import { convertToNumber } from "../../Utils/helper";
import { gaTrigger } from "../../Utils/gaTrigger";
import { Tooltip, TooltipProps } from "@mui/material";
import { styled } from "styled-components";
import { FcInfo } from "react-icons/fc";
import {ROUTES_PATH} from "../../Utils/routes";

function Step1() {
  const [token, setToke] = useState("");
  const [jobType, setJopType] = useState(0);
  const [militaryRank, setMilitaryRank] = useState(0);
  const [employerName, setEmployerName] = useState("");
  const [netSalary, setNetSalary] = useState("");
  const [showPilote, setShowPilote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [basicSalary, setBasicSalary] = useState("");
  const [jobStartDate, setJobstartDate] = useState(
    JSON.parse(localStorage.getItem("step1"))?.jobStartDate || new Date()
  );
  const [selectedMortgageValue, setSelectedMortgageValue] = React.useState("");
  const [pilote, isPilote] = useState(0);
  const [jobDate, setJobDate] = useState("");
  const [errors, setErrors] = useState({});
  const [salaryBank, setSalaryBank] = useState(
    JSON.parse(localStorage.getItem("step1"))?.salaryBank || ""
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function numericToArabic(number) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    const numericString = String(number);
    let arabicString = "";
    for (let i = 0; i < numericString.length; i++) {
      const digit = parseInt(numericString[i]);
      arabicString += arabicDigits[digit];
    }

    return arabicString;
  }
  useEffect(() => {
    const token = localStorage.getItem("Token");
    const body = localStorage.getItem("step1");
    if (body) {
      // setNetSalary(
      //   i18n.language == "en"
      //     ? JSON.parse(body)?.netSalary
      //     : numericToArabic(JSON.parse(body)?.netSalary) || ""
      // );
      setNetSalary(JSON.parse(body)?.netSalary);
      setJopType(JSON.parse(body)?.workSector || "");
      setEmployerName(JSON.parse(body)?.employerName || "");
      setMilitaryRank(JSON.parse(body)?.militaryRank || "");
      isPilote(JSON.parse(body)?.isPilot == false ? 1 : 2 || "");
      // setBasicSalary(
      //   i18n.language == "en"
      //     ? JSON.parse(body)?.basicSalary
      //     : numericToArabic(JSON.parse(body)?.basicSalary) || ""
      // );
      setBasicSalary(JSON.parse(body)?.basicSalary);
      setSelectedMortgageValue(JSON.parse(body)?.mortgageType);
    }
    setToke(token);
  }, []);
  function arabicToNumeric(arabicNumber) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let numericValue = 0;
    let numberAr = String(arabicNumber)?.replaceAll(",", "");
    for (let i = 0; i < numberAr?.length; i++) {
      const digit = arabicDigits?.indexOf(numberAr[i]);
      if (digit !== -1) {
        numericValue = numericValue * 10 + digit;
      } else {
        return "";
      }
    }
    return numericValue;
  }

  const StyledIcon = styled(FcInfo)`
    & > circle:first-child {
      fill: var(--Brand-Green, #52b44a);
    }
  `;

  const TooltipIsThisYourFirstHome = ({ title }) => {
    const { t } = useTranslation();

    return (
      <Tooltip title={title} arrow placement="top">
        <button style={{ background: "none", verticalAlign: "middle" }}>
          <StyledIcon />
        </button>
      </Tooltip>
    );
  };

  const types = [
    {
      value: 1,
      name: t("selectBtn1"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.5 17.4999H17.5M5 14.9999V8.33319M8.33333 14.9999V8.33319M11.6667 14.9999V8.33319M15 14.9999V8.33319M16.6667 5.83319L10.3533 1.88736C10.2252 1.80724 10.1611 1.76719 10.0923 1.75157C10.0315 1.73776 9.96845 1.73776 9.90767 1.75157C9.83894 1.76719 9.77485 1.80724 9.64667 1.88736L3.33333 5.83319H16.6667Z"
            stroke={jobType == 1 ? "#FFF" : "#52B44A"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      value: 2,
      name: t("selectBtn2"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9.41802 18.0122C9.60251 18.1198 9.69475 18.1737 9.82494 18.2016C9.92597 18.2232 10.0734 18.2232 10.1744 18.2016C10.3046 18.1737 10.3968 18.1198 10.5813 18.0122C12.2047 17.0651 16.6663 14.0902 16.6663 9.99981V6.01448C16.6663 5.34822 16.6663 5.01509 16.5574 4.72873C16.4611 4.47576 16.3047 4.25004 16.1016 4.07109C15.8718 3.86851 15.5598 3.75154 14.936 3.5176L10.4678 1.84204C10.2946 1.77707 10.208 1.74459 10.1189 1.73171C10.0398 1.72029 9.95954 1.72029 9.88049 1.73171C9.79138 1.74459 9.70476 1.77707 9.53151 1.84204L5.06334 3.5176C4.43951 3.75154 4.12759 3.86851 3.89772 4.07109C3.69466 4.25004 3.53824 4.47576 3.44197 4.72873C3.33301 5.01509 3.33301 5.34822 3.33301 6.01448V9.99981C3.33301 14.0902 7.79464 17.0651 9.41802 18.0122Z"
            stroke={jobType == 2 ? "#FFF" : "#52B44A"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      value: 3,
      name: t("selectBtn3"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.5 17.5V13C12.5 12.5333 12.5 12.2999 12.4092 12.1217C12.3293 11.9649 12.2018 11.8374 12.045 11.7575C11.8667 11.6667 11.6334 11.6667 11.1667 11.6667H8.83333C8.36662 11.6667 8.13327 11.6667 7.95501 11.7575C7.79821 11.8374 7.67072 11.9649 7.59083 12.1217C7.5 12.2999 7.5 12.5333 7.5 13V17.5M2.5 5.83333C2.5 7.21405 3.61929 8.33333 5 8.33333C6.38071 8.33333 7.5 7.21405 7.5 5.83333C7.5 7.21405 8.61929 8.33333 10 8.33333C11.3807 8.33333 12.5 7.21405 12.5 5.83333C12.5 7.21405 13.6193 8.33333 15 8.33333C16.3807 8.33333 17.5 7.21405 17.5 5.83333M5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7668 17.5 14.8333V5.16667C17.5 4.23325 17.5 3.76654 17.3183 3.41002C17.1586 3.09641 16.9036 2.84144 16.59 2.68166C16.2335 2.5 15.7668 2.5 14.8333 2.5H5.16667C4.23325 2.5 3.76654 2.5 3.41002 2.68166C3.09641 2.84144 2.84144 3.09641 2.68166 3.41002C2.5 3.76654 2.5 4.23325 2.5 5.16667V14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5Z"
            stroke={jobType == 3 ? "#FFF" : "#52B44A"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      value: 4,
      name: t("selectBtn4"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M6.25033 9.16667H3.83366C3.36695 9.16667 3.13359 9.16667 2.95533 9.25749C2.79853 9.33739 2.67105 9.46487 2.59115 9.62167C2.50033 9.79993 2.50033 10.0333 2.50033 10.5V17.5M13.7503 9.16667H16.167C16.6337 9.16667 16.8671 9.16667 17.0453 9.25749C17.2021 9.33739 17.3296 9.46487 17.4095 9.62167C17.5003 9.79993 17.5003 10.0333 17.5003 10.5V17.5M13.7503 17.5V5.16667C13.7503 4.23325 13.7503 3.76654 13.5687 3.41002C13.4089 3.09641 13.1539 2.84144 12.8403 2.68166C12.4838 2.5 12.0171 2.5 11.0837 2.5H8.91699C7.98357 2.5 7.51686 2.5 7.16034 2.68166C6.84674 2.84144 6.59177 3.09641 6.43198 3.41002C6.25033 3.76654 6.25033 4.23325 6.25033 5.16667V17.5M18.3337 17.5H1.66699M9.16699 5.83333H10.8337M9.16699 9.16667H10.8337M9.16699 12.5H10.8337"
            stroke={jobType == 4 ? "#FFF" : "#52B44A"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      value: 5,
      name: t("selectBtn5"),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M17.5 8.33317H2.5M17.5 9.58317V7.33317C17.5 5.93304 17.5 5.23297 17.2275 4.69819C16.9878 4.22779 16.6054 3.84534 16.135 3.60565C15.6002 3.33317 14.9001 3.33317 13.5 3.33317H6.5C5.09987 3.33317 4.3998 3.33317 3.86502 3.60565C3.39462 3.84534 3.01217 4.22779 2.77248 4.69819C2.5 5.23297 2.5 5.93304 2.5 7.33317V14.3332C2.5 15.7333 2.5 16.4334 2.77248 16.9681C3.01217 17.4386 3.39462 17.821 3.86502 18.0607C4.3998 18.3332 5.09987 18.3332 6.5 18.3332H10.4167M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M14.5813 13.0931C13.9982 12.4438 13.0258 12.2692 12.2951 12.8637C11.5645 13.4583 11.4616 14.4523 12.0354 15.1555C12.6092 15.8587 14.5813 17.4998 14.5813 17.4998C14.5813 17.4998 16.5535 15.8587 17.1273 15.1555C17.7011 14.4523 17.6107 13.452 16.8676 12.8637C16.1244 12.2754 15.1645 12.4438 14.5813 13.0931Z"
            stroke={jobType == 5 ? "#FFF" : "#52B44A"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];
  const disableCases = {
    1: !jobType || !netSalary || !basicSalary || !jobDate || !employerName,
    2:
      !jobType ||
      !netSalary ||
      !basicSalary ||
      !jobDate ||
      !pilote ||
      !militaryRank ||
      !employerName,
    3: !jobType || !netSalary || !basicSalary || !jobDate || !employerName,
    4: !jobType || !netSalary || !basicSalary || !jobDate || !employerName,
    5: !jobType || !netSalary,
  };
  const requestBodyes = {
    1: {
      workSector: jobType,
      netSalary: String(convertToNumber(netSalary)),
      basicSalary: String(convertToNumber(basicSalary)),
      housingAllowance: "0",
      otherAllowance: 0,
      employerName: employerName,
      salaryBank: salaryBank,

      token: token,
    },
    2: {
      workSector: jobType,
      employerName: employerName,
      militaryRank: +militaryRank,
      isPilot: pilote == 1 ? false : true,
      netSalary: String(convertToNumber(netSalary)),
      basicSalary: String(convertToNumber(basicSalary)),
      // netSalary:
      //   i18n.language == "en"
      //     ? String(netSalary)
      //     : "" + arabicToNumeric(netSalary),
      // basicSalary:
      //   i18n.language == "en"
      //     ? String(basicSalary)
      //     : "" + arabicToNumeric(basicSalary),
      housingAllowance: "0",
      otherAllowance: 0,
      salaryBank: salaryBank,

      token: token,
    },
    3: {
      workSector: jobType,
      employerName: employerName,
      netSalary: String(convertToNumber(netSalary)),
      basicSalary: String(convertToNumber(basicSalary)),
      // netSalary:
      //   i18n.language == "en"
      //     ? String(netSalary)
      //     : "" + arabicToNumeric(netSalary),
      // basicSalary:
      //   i18n.language == "en"
      //     ? String(basicSalary)
      //     : "" + arabicToNumeric(basicSalary),
      housingAllowance: "0",
      otherAllowance: 0,
      salaryBank: salaryBank,

      token: token,
    },
    4: {
      workSector: jobType,
      netSalary: String(convertToNumber(netSalary)),
      basicSalary: String(convertToNumber(basicSalary)),
      // netSalary:
      //   i18n.language == "en"
      //     ? String(netSalary)
      //     : "" + arabicToNumeric(netSalary),
      // basicSalary:
      //   i18n.language == "en"
      //     ? String(basicSalary)
      //     : "" + arabicToNumeric(basicSalary),
      employerName: employerName,
      housingAllowance: "0",
      otherAllowance: 0,
      salaryBank: salaryBank,

      token: token,
    },
    5: {
      workSector: jobType,
      netSalary: String(convertToNumber(netSalary)),
      // netSalary:
      //   i18n.language == "en"
      //     ? String(netSalary)
      //     : "" + arabicToNumeric(netSalary),
      token: token,
    },
  };
  let errormsgs = {
    ar: convertToNumber(netSalary) &&
      convertToNumber(basicSalary) &&
      convertToNumber(netSalary) < convertToNumber(basicSalary) && (
        <span className="error">{t("salaryComparison")}</span>
      ),
    en: convertToNumber(netSalary) &&
      convertToNumber(basicSalary) &&
      convertToNumber(netSalary) < convertToNumber(basicSalary) && (
        <span className="error">
          {"Net salary can’t be less than sum of basic salary"}
        </span>
      ),
  };

  const normilezeBody = (obj) => {
    if (
      obj.militaryRank &&
      !(obj.militaryRank <= 10 && obj.militaryRank !== 0)
    ) {
      delete obj.isPilot;
    }
    if (!obj.salaryBank) {
      delete obj.salaryBank;
    }
    return obj;
  };
  function removeDuplicateObjects(array) {
    const uniqueLabels = new Set(); // To store unique labels
    const uniqueObjects = []; // To store unique objects

    // Iterate over each object in the array
    array.forEach((obj) => {
      // Check if the label of the current object is not in the set of unique labels
      if (!uniqueLabels.has(obj.label)) {
        // Add the label to the set
        uniqueLabels.add(obj.label);
        // Add the object to the array of unique objects
        uniqueObjects.push(obj);
      }
    });

    return uniqueObjects;
  }

  const validateInputs = () => {
    let newErrors = {};

    // Add validation for jobType
    if (!jobType) {
      newErrors.jobType = t("jobTypeRequired");
    }

    // Validation for employerName
    if (jobType !== 5 && employerName === "") {
      newErrors.employerName = t("employerNameRequired");
    }

    // Validation for militaryRank
    if (jobType === 2 && (militaryRank === 0 || !militaryRank)) {
      newErrors.militaryRank = t("militaryRankRequired");
    }

    // Validation for pilote
    if (jobType === 2 && militaryRank <= 20 && militaryRank !== 0 && !pilote) {
      newErrors.pilote = t("piloteRequired");
    }

    // Validation for netSalary
    if (netSalary === "" || netSalary === undefined || netSalary === null) {
      newErrors.netSalary = t("netSalaryRequired");
    } else {
      if (errormsgs[i18n.language]) {
        newErrors.salaryComparison = t("salaryComparison");
      }
    }

    // Validation for basicSalary
    if (
      jobType !== 5 &&
      (basicSalary === "" || basicSalary === undefined || basicSalary === null)
    ) {
      newErrors.basicSalary = t("basicSalaryRequired");
    }

    // Validation for salaryBank
    if (jobType !== 5 && salaryBank === "") {
      newErrors.salaryBank = t("salaryBankRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateWork = (body) => {
    if (!validateInputs()) {
      return;
    }
    let token = localStorage.getItem("Token");

    if (!token) {
      token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Miwicm9sZSI6MSwiaWF0IjoxNzE5MTQ1Njc1LCJleHAiOjE3MTkyMzIwNzV9.nQSGPaZL-CPXbck_t5c23fKjpvf6WWjiXPA0g9RTI5c";
      localStorage.setItem("Token", token);
    }

    // Use token from localStorage if body.token is null
    if (!body.token) {
      body.token = token;
    }
    setLoading(true);
    let data = normilezeBody(body);
    localStorage.setItem(
      "step1",
      JSON.stringify({
        ...body,
        mortgageType: selectedMortgageValue,
        netSalary,
        basicSalary,
      })
    );

    // Retrieve the token again from local storage before making the request
    token = localStorage.getItem("Token");
    axios
      .put("/leads/update-lead-work", {
        ...data,
        mortgageType: selectedMortgageValue,
      })
      .then(() => {
        setLoading(false);
        navigate("/obligations");
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  const onNext = () => {
    gaTrigger("workinfo_trigger");
    updateWork(requestBodyes[jobType]);
  };

  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={10} />
        <p className="progress-value">10%</p>
      </div>
      <h1 className="header-text">{t("title2")}</h1>
      <div className="info">
        <p className="info-text">{t("selectButton1")}</p>
        <div className="selection-container">
          {types.map((i, key) => (
            <Buttons
              name={i.name}
              key={key}
              value={i.value}
              jobType={jobType}
              setType={setJopType}
            >
              {i.icon}
            </Buttons>
          ))}
        </div>
        {errors.jobType && (
          <span className="error">{t("jobTypeRequired")}</span>
        )}
        {jobType == 2 ? (
          <InputSelect
            value={militaryRank}
            name={t("Rank")}
            placeHolder={t("Rank")}
            changeValue={setMilitaryRank}
            options={
              i18n.language === "en"
                ? removeDuplicateObjects(MilitaryArray)
                : removeDuplicateObjects(MilitaryArrayAb)
            }
          />
        ) : null}
        {errors.militaryRank && (
          <p className="error">{t("militaryRankRequired")}</p>
        )}
        {jobType == 2 &&
        militaryRank <= 20 &&
        militaryRank !== 0 &&
        militaryRank !== "" ? (
          <div className="radio-wraper">
            <p className="info-text">{t("Pilote")}</p>
            <div>
              <ButtonWithRadio
                name={t("no")}
                value={1}
                pilote={pilote}
                setPilote={isPilote}
              />
              <ButtonWithRadio
                name={t("yes")}
                value={2}
                pilote={pilote}
                setPilote={isPilote}
              />
            </div>
          </div>
        ) : null}
        {jobType == 2 &&
          militaryRank <= 20 &&
          militaryRank !== 0 &&
          militaryRank !== "" &&
          errors.pilote && <span className="error">{t("piloteRequired")}</span>}
        {jobType !== 5 ? (
          <Input
            value={employerName}
            name={t("inputTitle1")}
            placeHolder={t("placeHolder1")}
            changeValue={setEmployerName}
          />
        ) : null}
        {errors.employerName && (
          <span className="error">{t("employerNameRequired")}</span>
        )}
        <div className="salary-fields-wrapper">
          {jobType !== 5 ? (
            <div className="salary-container">
              <InputWithSelect
                value={basicSalary}
                title={t("inputTitle2")}
                placeHolder={t("placeHolder2")}
                changeValue={setBasicSalary}
              />
              {errors.basicSalary && (
                <span className="error">{t("basicSalaryRequired")}</span>
              )}
            </div>
          ) : null}
          <div className="salary-container">
            <div style={{ width: "100%", marginTop: 20 }}>
              <InputWithSelect
                // info={
                //   <TooltipIsThisYourFirstHome
                //     title={
                //       <div>
                //         <span>{t("placeHolderInfo")}</span>
                //       </div>
                //     }
                //   />
                // }
                value={netSalary}
                title={
                  <div className="tooltip-outer-container">
                    {t("inputTitle3")}
                    {/* <div className="tooltip-container">
                      <TooltipIsThisYourFirstHome
                        title={t("placeHolderInfo")}
                      />
                    </div> */}
                  </div>
                }
                placeHolder={t("placeHolder2")}
                changeValue={setNetSalary}
              />
            </div>
            {errors.netSalary && (
              <span className="error">{t("netSalaryRequired")}</span>
            )}
            {errormsgs[i18n.language] == "0" ? "" : errormsgs[i18n.language]}
          </div>
        </div>

        {/* {jobType !== 5 ? <DataPicker value={new Date(jobStartDate)} title={t('inputTitle4')} changeValue={setJobstartDate}/> : null} */}
        <div></div>
        <InputSelect
          value={salaryBank}
          name={t("inputTitle5")}
          placeHolder={t("placeHolder5")}
          options={i18n.language == "en" ? salaryOptions : salaryOptionsAb}
          changeValue={setSalaryBank}
        />
        {errors.salaryBank && (
          <span className="error">{t("salaryBankRequired")}</span>
        )}

        <InputSelect
          value={selectedMortgageValue}
          name={t("typeOfMortgage")}
          options={typesOfMortgageDropDownValues.map((type) => ({
            ...type,
            label: t(type.label),
          }))}
          changeValue={setSelectedMortgageValue}
        />
      </div>
      <div className="btn-container">
        <BackButton disabled={false} url={ROUTES_PATH.HOME_PURCHASE_INTENTIONS} />
        <NextButton onClick={onNext} loading={loading} />
      </div>
    </div>
  );
}

export default Step1;
