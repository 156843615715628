import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useForm} from 'react-hook-form';
// Components
import Progress from "../../../components/Progress";
import RadioButtons from "../../../components/RadioButtons/RadioButtons";
import NextButton from "../../../components/NextButton";
// Styles
import {HomeTitle, StyledFormControl} from "./HomePurchaseIntentions.styled";
// Helpers
import {
  typesOfHowWillYouUseYourNewHomeOptions, typesOfIsPropertyReadyOptions,
  typesOfWhenAreYouLookingToBuyOptions,
  typesOfWhereAreYouInYourHomeBuying
} from "../../../Utils/options";
import {RADIO_BUTTONS_NAME} from "./util";
const HomePurchaseIntentions = () => {
  const navigate = useNavigate()
  const {t} = useTranslation();
  const [buyingJourneyOptions, setBuyingJourneyOptions] = useState([]);
  const [optionsData, setOptionsData] = useState({
    [RADIO_BUTTONS_NAME.BUYING_JOURNEY]: '',
    [RADIO_BUTTONS_NAME.BUYING_TIMELINE]: '',
    [RADIO_BUTTONS_NAME.USAGE_PLAN]: '',
    [RADIO_BUTTONS_NAME.IS_PROPERTY_READY]: '',
  });
  const {
    register,
    handleSubmit,
    formState: {errors},
    resetField,
    reset
  } = useForm({
    defaultValues: {...optionsData}
  });
  // Load stored values on mount
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("buyingJourneyOptions"));
    if (storedData) {
      const storedValues = {
        [RADIO_BUTTONS_NAME.BUYING_JOURNEY]: storedData[RADIO_BUTTONS_NAME.BUYING_JOURNEY]?.toString(),
        [RADIO_BUTTONS_NAME.BUYING_TIMELINE]: storedData[RADIO_BUTTONS_NAME.BUYING_TIMELINE]?.toString(),
        [RADIO_BUTTONS_NAME.IS_PROPERTY_READY]: storedData[RADIO_BUTTONS_NAME.IS_PROPERTY_READY]?.toString(),
        [RADIO_BUTTONS_NAME.USAGE_PLAN]: storedData[RADIO_BUTTONS_NAME.USAGE_PLAN]?.toString()
      };
      reset(storedValues);
      Object.entries(storedValues).forEach(([key, value]) => {
        handleRadioChange(key, value, true);
      })
    }
  }, []);
// Handle form submission
  const onSubmit = (data) => {
    console.log({data})
    localStorage.setItem("buyingJourneyOptions", JSON.stringify(data));
    navigate("/workinfo");
  };
// Update available options based on property readiness
  const showBuyingJourneyOptions = (isPropertyReady) => {
    const filteredOptions = isPropertyReady === typesOfIsPropertyReadyOptions[0].value
      ? typesOfWhereAreYouInYourHomeBuying.slice(1)
      : typesOfWhereAreYouInYourHomeBuying.slice(0, -2);
    setBuyingJourneyOptions(filteredOptions);
  };
// Handle radio button changes and reset dependent fields
  const handleRadioChange = (name, value, isMounting = false) => {
    setOptionsData(prev => ({...prev, [name]: value}));
    if (name === RADIO_BUTTONS_NAME.IS_PROPERTY_READY) {
      !isMounting && resetField(RADIO_BUTTONS_NAME.BUYING_JOURNEY);
      showBuyingJourneyOptions(value);
    }
  };
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={0}/>
        <p className="progress-value">0%</p>
      </div>
      <HomeTitle>{t('purchaseIntentionsTitle')}</HomeTitle>
      <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
        <StyledFormControl component="fieldset" error={!!errors.radioGroup} sx={{width: '100%'}}>
          <RadioButtons
            value={optionsData[RADIO_BUTTONS_NAME.IS_PROPERTY_READY]}
            options={typesOfIsPropertyReadyOptions}
            title={t('isMortgageReady')}
            onChange={handleRadioChange}
            register={register}
            errors={errors[RADIO_BUTTONS_NAME?.IS_PROPERTY_READY] && t('pleaseAnswerAllRequiredQuestions')}
            name={RADIO_BUTTONS_NAME?.IS_PROPERTY_READY}
          />
          {optionsData[RADIO_BUTTONS_NAME.IS_PROPERTY_READY] && (
            <RadioButtons
              value={optionsData[RADIO_BUTTONS_NAME.BUYING_JOURNEY]}
              options={buyingJourneyOptions}
              title={t('whereAreYouInYourHomeBuyingJourney')}
              onChange={handleRadioChange}
              register={register}
              errors={errors[RADIO_BUTTONS_NAME?.BUYING_JOURNEY] && t('pleaseAnswerAllRequiredQuestions')}
              name={RADIO_BUTTONS_NAME?.BUYING_JOURNEY}
            />
          )}
          {optionsData[RADIO_BUTTONS_NAME.BUYING_JOURNEY] && (
            <RadioButtons
              register={register}
              errors={errors[RADIO_BUTTONS_NAME?.USAGE_PLAN] && t('pleaseAnswerAllRequiredQuestions')}
              value={optionsData[RADIO_BUTTONS_NAME.USAGE_PLAN]}
              options={typesOfHowWillYouUseYourNewHomeOptions}
              title={t('howWillYouUseYourNewHome')}
              onChange={handleRadioChange}
              name={RADIO_BUTTONS_NAME?.USAGE_PLAN}
            />
          )}
          {optionsData[RADIO_BUTTONS_NAME.USAGE_PLAN] && (
            <RadioButtons
              register={register}
              errors={errors[RADIO_BUTTONS_NAME?.BUYING_TIMELINE] && t('pleaseAnswerAllRequiredQuestions')}
              value={optionsData[RADIO_BUTTONS_NAME.BUYING_TIMELINE]}
              options={typesOfWhenAreYouLookingToBuyOptions}
              title={t('whenAreYouLookingToBuy')}
              onChange={handleRadioChange}
              name={RADIO_BUTTONS_NAME?.BUYING_TIMELINE}
            />
          )}
          <div className="btn-container">
            <NextButton type={'submit'}/>
          </div>
        </StyledFormControl>
      </form>
    </div>
  )
}
export default HomePurchaseIntentions;
