import {styled} from "styled-components";
import {FormControl, FormControlLabel, FormLabel, RadioGroup} from "@mui/material";

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 10px;
  border-radius: 12px;
  color: ${({ isSelected }) => (isSelected && '#52B44A')};


  .MuiTypography-root {
    font-weight: ${({ isSelected }) => (isSelected && '600' )};
  }

  .MuiRadio-root.Mui-checked {
    color: #52B44A;
  }

  .MuiButtonBase-root {
    padding: 12px;
  }

  &.MuiFormControlLabel-root {
    background-color: ${({ isSelected }) => (isSelected ? 'white' : '#F9FAFB')};
    border: ${({ isSelected }) => (isSelected &&'1px solid #52B44A' )};
    box-shadow: ${({ isSelected }) => (isSelected && '0px 4px 20px 0px #00280A1F')};
  }
`;



const StyledRadioGroup = styled(RadioGroup)`
  gap:12px;
  & > label{
    background-color: #F9FAFB;
    margin:0;
    font-size: 18px;

  }
`
const StyledFormControl = styled(FormControl)`
  width: 100%;
`


const StyledFormLabel= styled.div`
  margin-bottom: 12px;
  font-weight: 600;
  font-size:16px;
`
export {
  StyledFormControlLabel,
  StyledFormControl,
  StyledRadioGroup,
  StyledFormLabel
}
