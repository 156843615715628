import React from 'react'
import { DatePicker } from 'rsuite';

function DataPicker({title,changeValue,value}) {
  return (
    <div className='input-label'>
     <p>{title}</p>
     <DatePicker placement="topStart" format='dd/MM/yyyy'  size='lg' placeholder='DD/MM/YY' onChange={(e)=>changeValue(e)}  value={value} />
    </div>
  )
}
 
export default DataPicker