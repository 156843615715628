import TagManager from "react-gtm-module";

export const gaTrigger = (eventName) => {
  if (eventName) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
      },
    });
  }
};
