import React from 'react';
import {Radio} from '@mui/material';
import {StyledFormControlLabel, StyledFormControl, StyledRadioGroup, StyledFormLabel} from "./RadioButton.styled";
import {useTranslation} from "react-i18next";
const RadioButtons = ({value, options,  title, onChange, name = '',register,errors}) => {
  const {t} = useTranslation()
  return (
    <StyledFormControl>
      <StyledFormLabel>{title}</StyledFormLabel>
      <StyledRadioGroup
        value={value}
        name="radio-buttons-group"
        onChange={(e) => {
          onChange(name, e.target.value)
        }}
      >
        {options.map((option) => {
          return(
            <StyledFormControlLabel
              isSelected={value === option.value}
              key={option.value}
              value={option.value}
              control={<Radio/>}
              {...register(name, { required: `${title} is required` })} // react-hook-form register
              label={t(option.label)}
            />
          )})}
      </StyledRadioGroup>
      {errors && <p style={{ color: 'red', marginTop:'12px' }}>{errors}</p>}
    </StyledFormControl>
  );
};
export default RadioButtons;
