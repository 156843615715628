import React from "react";
import { useEffect } from "react";
import "./Start.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Progress from "../../components/Progress";
import briefCase from "./../../imgs/briefcase-02.png";
import building from "./../../imgs/building-08.png";
import receipt from "./../../imgs/receipt.png";
import { gaTrigger } from "../../Utils/gaTrigger";
import {ROUTES_PATH} from "../../Utils/routes";

function Start() {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("token")) {
      localStorage.setItem("Token", urlParams.get("token"));

    }
    localStorage.removeItem('buyingJourneyOptions')
    navigate("?lang=ar");
  }, []);

  const onNextClick = () => {
    gaTrigger("landing_trigger");
    navigate(ROUTES_PATH.HOME_PURCHASE_INTENTIONS);
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={1} />
        <p className="progress-value">0%</p>
      </div>
      <h1 className="header-text">{t("title1")}</h1>
      <div className="info" style={{ width: "100%" }}>
        <p className="info-text">{t("infoTitle")}</p>
        <div className="info-container">
          <div className="row">
            <img src={briefCase} />
            <p>{t("infoItem1")}</p>
          </div>
          <div className="row">
            <img src={building} />
            <p>{t("infoItem2")}</p>
          </div>
          <div className="row">
            <img src={receipt} />
            <p>{t("infoItem3")}</p>
          </div>
        </div>
      </div>
      <p className="text">{t("infoText")}</p>
      <div className="btn-container">
        <button className="green-btn" onClick={onNextClick}>
          {t("btn1")}
        </button>
      </div>
    </div>
  );
}

export default Start;
