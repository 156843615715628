import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Progress from "../../components/Progress";
import {useEffect} from "react";
import BackButton from "../../components/BackButton";
import Liabilitie from "../../components/Liabilitie";
import userIcon from "./../../imgs/user-01.png";
import carIcon from "./../../imgs/car-01.png";
import ButtonWithRadio from "../../components/ButtonWithRadio";
import homeIcon from "./../../imgs/home-05.png";
import CardIcon from "./../../imgs/Bank Card information 1.png";
import emnkanIcon from "./../../imgs/image 3.png";
import Cytezen from "./../Steps/Cytezen";
import Modla from "../../components/Modla";
import Input from "../../components/Input";
import Slider from "../../components/Slider";
import Sliders from "../../components/Slider";
import {toast} from "react-toastify";
import i18n from "../../i18";
import InputWithSelect from "../../components/InputWithSelect";
import axios from "axios";
import {convertToNumber} from "../../Utils/helper";
import {gaTrigger} from "../../Utils/gaTrigger";
import {
  typesOfLoansDropDownValues,
} from "../../Utils/options";
import InputSelect from "../../components/InputSelect";

function Step2() {
  const [simah, isSimah] = useState(0);
  const [hasLoans, setHasLoans] = useState(false);
  const [token, setToken] = useState("");
  const [personalLiabilities, setPersonalLiabilities] = useState([]);
  const [carLiabilities, setCarlLiabilities] = useState([]);
  const [mortgageLiabilities, setMortgagelLiabilities] = useState([]);
  const [cardLiabilities, setCardlLiabilities] = useState([]);
  const [emkan, setEmkan] = useState([]);
  const [amount, setAmount] = useState("");
  const [mortgageType, setMortgageType] = useState();
  const [date, setDate] = useState(6);
  const [errors, setErrors] = useState({});
  const [codeData, setCodeData] = useState({
    loanType: '',

  });

  useEffect(() => {
    const token = localStorage.getItem("Token");
    const body = localStorage.getItem("step2");
    const step1Body = localStorage.getItem("step1");
    if (step1Body) {
      setMortgageType(JSON.parse(step1Body)?.mortgageType)
    }
    if (body) {
      // setAmount(
      //   i18n.language == "ar"
      //     ? numericToArabic(+JSON.parse(body)?.obligations[0]?.amount)
      //     : +JSON.parse(body)?.obligations[0]?.amount || ""
      // );
      setAmount(JSON.parse(body)?.obligations[0]?.amount || ""
      );
      isSimah(JSON.parse(body)?.defaultSimah == false ? 1 : 2);
      setDate(JSON.parse(body)?.obligations[0]?.monthsRemaining / 12 || 0.5);
    }
    setToken(token);
  }, []);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const changesFunctions = {
    1: setPersonalLiabilities,
    2: setCarlLiabilities,
    3: setMortgagelLiabilities,
    4: setCardlLiabilities,
    5: setEmkan,
  };

  const valuees = {
    1: personalLiabilities,
    2: carLiabilities,
    3: mortgageLiabilities,
    4: cardLiabilities,
    5: emkan,
  };

  function arabicToNumeric(arabicNumber) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    let numericValue = 0;
    let numberAr = arabicNumber?.replaceAll(",", "");
    for (let i = 0; i < numberAr?.length; i++) {
      const digit = arabicDigits?.indexOf(numberAr[i]);
      if (digit !== -1) {
        numericValue = numericValue * 10 + digit;
      } else {
        return NaN;
      }
    }

    return numericValue;
  }

  function numericToArabic(number) {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    const numericString = String(number);
    let arabicString = "";
    for (let i = 0; i < numericString.length; i++) {
      const digit = parseInt(numericString[i]);
      arabicString += arabicDigits[digit];
    }

    return arabicString;
  }

  const validateInputs = () => {
    let newErrors = {};

    // Validation for liabilities
    if (amount === "" && hasLoans) {
      newErrors.amount = t("liabilitiesRequired");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateWork = (body) => {
    if (!validateInputs()) {
      return;
    }

    localStorage.setItem("step2", JSON.stringify({
      ...body,
      obligations: [
        {
          obligationType: mortgageType,
          amount: amount,
          monthsRemaining: date * 12,
        },
      ],
    }));
    localStorage.setItem("obligationAmount", JSON.stringify(hasLoans ? convertToNumber(amount) : 0));
    axios
      .put("/leads/update-lead-obligations", body)
      .then(() => navigate("/eligible"))
      .catch((e) => toast.error(e?.message));
  };
  const body = {
    obligations: [
      {
        hasAnyLoan: hasLoans,
        ...(hasLoans ? {
          obligationType: mortgageType,
          amount: convertToNumber(amount),
          monthsRemaining: date * 12,
          ...(codeData.loanType ? {loanType: codeData.loanType} : {}),
        } : {
          obligationType: mortgageType,
          amount: 0,
          monthsRemaining: 0,
        })

        // i18n.language == "ar" ? "" + arabicToNumeric("" + amount) : amount,

      },
    ],
    defaultSimah: simah == 1 ? false : true,
    token: token,
  };

  const onNext = () => {
    gaTrigger("obligations_trigger");
    updateWork(body);
  };
  return (
    <div className="conatainer">
      <div className="progress-container">
        <Progress done={30}/>
        <p className="progress-value">30%</p>
      </div>
      <h1 className="header-text">{t("title3")}</h1>

      <div
        style={{paddingTop: "0px", width: "100%"}}
        className="radio-wraper"
      >
        <p className="info-text">{t("doYouHaveAnyLoans")}</p>
        <div>

          <ButtonWithRadio
            name={t("yes")}
            value={true}
            pilote={hasLoans}
            setPilote={setHasLoans}
          />
          <ButtonWithRadio
            name={t("no")}
            value={false}
            pilote={hasLoans}
            setPilote={setHasLoans}
          />
        </div>
      </div>

      {!!hasLoans && (
        <>
          <p className="info-text">{t("liabilitiesTitle")}</p>
          <InputWithSelect
            type={"number"}
            name={t("ammount")}
            style={{paddingTop: "0"}}
            value={amount}
            changeValue={setAmount}
            placeHolder={t("ammount")}
          />
          {errors.amount && (
            <span className="error">{t("liabilitiesRequired")}</span>
          )}
          <p className="info-text">{t("liabilitiesSliderTitle")}</p>
          <span className={'progress-value-200'}>{date}</span>
          <Sliders value={date} setValue={setDate}/>


          <InputSelect
            value={codeData.loanType}
            name={t("typeOfLoans")}
            options={typesOfLoansDropDownValues.map((type) => ({
              ...type,
              label: t(type.label)
            }))}
            changeValue={(value) => {
              setCodeData({
                ...codeData,
                loanType: value
              })
            }}
          />
        </>
      )}

      <div
        style={{paddingTop: "0px", width: "100%"}}
        className="radio-wraper"
      >
        <p className="info-text">{t("simahText")}</p>
        <div>
          <ButtonWithRadio
            name={t("simahValue1")}
            value={2}
            pilote={simah}
            setPilote={isSimah}
          />
          <ButtonWithRadio
            name={t("simahValuw2")}
            value={1}
            pilote={simah}
            setPilote={isSimah}
          />
        </div>
      </div>

      <div className="btn-container">
        <BackButton url="/workinfo"/>
        <button
          className="green-btn"
          style={{opacity: !simah ? "0.7" : "1"}}
          onClick={onNext}
          disabled={!simah}
        >
          {t("nextBtn")}
        </button>
      </div>
    </div>
  );
}

export default Step2;
